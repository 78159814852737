export const accent = '#f90000';

export const mainLeft = '#A7FFFF';
export const mainCenter = '#CDFFCB';
export const mainRight = '#FFA7A7';

export const textDark = '#01295F';
export const leftDark = '#25B1B2';
export const lightAccent = '#F8FFDA';

export const subtle = '#767676';
export const lightSubtle = '#EEEEEE';
